














import { Vue, Component, Provide } from "vue-property-decorator";
import Nav from "@/components/header.vue";
@Component({
  components: {
    Nav,
  },
})
export default class RechargeTips extends Vue {
  @Provide() num = "";
  @Provide() radio = "1";
  private goAccount(): void {
    location.href = `https://h5.glhgg.com/poket`
  }
}
